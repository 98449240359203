<template>
  <div>
    <!-- <Navbar /> -->
    <div class="text-center mb-4 fn24-weight7" style="color: #1a347f">
      My Rewards
    </div>
    <v-tabs v-model="tab" active-class="active" centered fixed-tabs>
      <v-tab v-for="(item, index) in tabs" :key="index" width="160">
        <span
          style="color: #1a347f"
          class="mt-3 fn12-weight7 text-capitalize text-center"
        >
          {{ item.name }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-4">
      <v-tab-item v-for="(item, index) in tabs" :key="index">
        <div v-for="(object, index) in items" :key="index" class="mt-2">
          <v-card
            class="mb-6 mt-4 rounded-xl mx-auto"
            max-width="374"
            min-width="345"
            max-height="auto"
            min-height="auto"
            elevation="2"
            @click="goTo(object)"
          >
            <!-- @click="goToCoupon(item.name)" -->

            <v-row class="pa-0">
              <v-col cols="4" class="py-0 pr-0">
                <!-- <div v-if="object.reward" class="rounded-l-xl">
                  <v-img :src="object.reward.rewardImg" />
                </div> -->
                <!-- border-radius: 15px 50px 30px 5px; -->
                <v-img
                  style="padding: 10px; border-radius: 25px 0px 0px 25px"
                  v-if="object.reward"
                  :src="object.reward.rewardImg"
                />
              </v-col>
              <v-col cols="7" class="pb-0 px-0">
                <div class="ml-8 mb-2 text-left pb-0">
                  <span class="fn20" style="color: #081f32; font-weight: 600"
                    >{{ object.redemption_name }}
                  </span>
                </div>
                <div class="ml-8 mt-4">
                  <span
                    class="fn11"
                    style="color: #081f32"
                    v-if="object.createdAt"
                  >
                    {{ convertDate(object.createdAt) }}
                  </span>
                </div>
                <!-- <div class="ml-8 mt-4">
                  <span
                    class="fn11"
                    style="color: #081f32"
                    v-if="object.updatedAt !== 'Expired'"
                  >
                    {{ convertDate(object.updatedAt) }}
                  </span>
                  <v-chip v-else color="#DFE2E9" style="color: #6e798c">
                    <span class="fn11" style="font-weight: 600">{{
                      convertDate(object.updatedAt)
                    }}</span>
                  </v-chip>
                </div> -->
                <v-row>
                  <v-col cols="6" offset="1">
                    <div class="text-left ml-2">
                      <span class="fn11" style="font-weight: 600">{{
                        object.tracking_number
                      }}</span>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div class="text-right">
                      <span class="fn11" style="font-weight: 600">{{
                        object.status
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
// import Navbar from "@/components/nav";
export default {
  components: {
    draggable,
    Loading,
    // Navbar,
  },
  filters: {
    limitHeader(val) {
      console.log(val);
      // return val.length > 40 ? val.slice(0, 25) + "..." : val;
    },
  },
  data() {
    return {
      tab: null,
      tabs: [
        {
          name: "History",
          objects: [],
        },
      ],
      items: [],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
      userData: [],
    };
  },
  async created() {
    // this.user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
    await this.getProfile();
    // await this.getUser();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    goTo(val) {
      console.log("goto", val);
      this.$router.push(
        `RedemptionDetail?redemptionId=${val.id}&isDetail=1&status=${val.status}&tracking=${val.tracking_number}`
      );
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY HH:mm");
    },
    async getUser() {
       const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U1a2cf1f80834b4db16b1f93b7b63cab6`
      );
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
      localStorage.setItem("userBiData", Encode.encode(this.userData));
      await this.getAllmyreward();
    },

    async getAllmyreward() {
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/redemption?userId=${this.userData.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      // console.log("getAllmyrewardpe", response.data.data);
      this.items = response.data.data;
      console.log("items", this.items);
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      //   //console.log(this.list);
      // }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    UpdateBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("EditBrand");
    },
    ViewBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewBrand");
    },
    async DeleteBrand(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/brands/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllmyreward();
        }
      });
    },
    goToCoupon(val) {
      if (val === "Available") {
        this.$router.push("couponPage");
      }
    },
  },
};
</script>

<style scoped>
.active {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
